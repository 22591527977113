import React, { useState, useEffect} from 'react';
import styles from './index.module.scss';
import api from '../../api';
import '../BaseShell/tailwind.css';
import {Modal} from "../ui/Modal";
import otpModalImage from '../../assets/otp-flow-logo.png';
import OtpTimer from "./OtpTimer";

const OtpComponent = ({ token, type , isModalOpen, trackContinueButtonPressed , userId, oldUserEmail, newEmailContent, isOTPSent}: { token: string,
    type : string,  isModalOpen: boolean,
    trackContinueButtonPressed:string, userId:number , oldUserEmail:string, newEmailContent:string, isOTPSent:boolean}) => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(true);
    const [inputVal, setInputVal] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [tokenVal, setToken] = useState<string>(token);
    const [otpSentText, setOtpSentText] =  useState<boolean>(isOTPSent);

    const INTERNAL_SERVER_ERROR = "Something went wrong, please try again";
    const EMPTY_OTP = "Please enter the OTP";
    const INCORRECT_OTP_ENTERED = "Incorrect OTP entered, please retry.";

    useEffect(() => {
        if (token == "dummyToken1"){
            // if rendered just after email validation with dummy token
            setIsFormOpen(true);
            setError("");
            setInputVal("");
            setOtpSentText(isOTPSent);
        } else if (token == "dummyToken2"){
            // if rendered due to any error in OTP generation
            setError(INTERNAL_SERVER_ERROR);
        } else {
            // if rendered after successful OTP generation with real token value
              setOtpSentText(isOTPSent);
        }

        setToken(token);

    }, [trackContinueButtonPressed]);

    useEffect(() => {
        setIsFormOpen(isModalOpen);
    }, [isModalOpen]);

    useEffect(() => {
        if (inputVal!="" && error==EMPTY_OTP) {
            setError("");
        }
    },[inputVal]) // <-- here put the parameter to listen


    const onCloseModal = () => {
        setIsFormOpen(false);
    };

    const sendOtp = () => {
        setError("");
        setInputVal("");
        setOtpSentText(false);
        const sendOtpPayload = {
            type : type,
            newEmail : newEmailContent,
            userId : userId,
        } ;
        api.otpEmail.sendOtp(sendOtpPayload)
            .then((response) => {
                setToken(response.jwtToken);
                setOtpSentText(true);
            }).catch((error: Error) => {
            let errorMessage = INTERNAL_SERVER_ERROR;
            if(error.message!= ""){
                errorMessage =  error.message;
                if (errorMessage == "Internal server error" || errorMessage == "Bad request validation failure"){
                    errorMessage = INTERNAL_SERVER_ERROR;
                }
            }
            setError(errorMessage);
        })
    }

    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInputVal(e.currentTarget.value);
    }

    const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()

        if (tokenVal == "dummyToken1" || tokenVal == "dummyToken1"){
            setError(INCORRECT_OTP_ENTERED);
            return;
        }

        setError("");

        const payload = {
            jwtToken : tokenVal,
            type : type,
            otp : inputVal,
        } ;

        if (inputVal == ""){
            setError(EMPTY_OTP);
        } else {
            api.otpEmail.initiateOTPVerify(payload)
                .then((response) => {
                    let form : HTMLFormElement = document.getElementById("employeeOnboarding") as HTMLFormElement
                    const submitButton = document.createElement('input')
                    submitButton.type="submit";
                    form.appendChild(submitButton);
                    submitButton.click();
                }).catch((error: Error) => {
                  var errorMessage = error?error.message : INTERNAL_SERVER_ERROR;
                setError(errorMessage);
            })

        }

    }

    return (
        <>
            <Modal showModal={isFormOpen} closeModal={onCloseModal} className={`${styles['otp-card']}`}>
                <form className={`${styles['otp-card-content']}`} style={{ marginLeft: 'auto',
                    marginRight: 'auto'}}>
                    <img style={{ width: 120, height:120 , marginLeft: 'auto',
                        marginRight: 'auto'}}  src={otpModalImage} alt={'logoooo'}/>
                    <h3 className={`${styles['otp-card-title']} text-white-o-80`} style={{ marginLeft: 'auto',
                        marginRight: 'auto', marginTop:24, marginBottom:0}}>
                        Enter OTP for verification
                    </h3>
                    <div style={{paddingTop:32, marginRight:"auto", marginLeft:"auto"}}> Changes to email addresses now require an OTP verification.<div>
                    </div> Please enter the OTP sent to your email address.</div>
                    <div className="text-2xl font-bold text-white-o-50" style={{paddingTop:36}}>
                        <input type='text' value={inputVal}  onChange={handleOnChange} className="my-auto"/>
                        <div style={{color: "#F36969", paddingTop:8}}> {error} </div>
                    </div>
                    {otpSentText && <div style={{color: 'rgba(255, 255, 255, 0.38)', paddingTop:8, fontSize: 15}}>
                        <span style={{color: 'rgba(255, 255, 255, 0.70)'}}>OTP sent to</span>
                        <span style={{color: 'rgba(255, 255, 255, 0.70)'}} className={`font-bold`}>  {oldUserEmail} </span>
                    </div>
                    }
                    <div  className="flex items-center" style={{height:30, color: 'rgba(255, 255, 255, 0.38)'}}>
                    <div style={{color: 'rgba(255, 255, 255, 0.38)'}}> Did not receive OTP yet?</div>
                        <OtpTimer duration={30} format="mmss" isVisible={true}>
                            {({
                                  timerDone,
                                  resetTimer,
                              }: {
                                timerDone: boolean;
                                resetTimer: () => void;
                            }) => (
                                <div>
                                    {timerDone? (
                                        <a style={{textTransform:"none", backgroundColor:"transparent", paddingTop:10, color: "#7fb2f9", paddingLeft:8}}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                sendOtp();
                                                resetTimer();
                                            }}
                                        >
                                            Resend
                                        </a>
                                    ) : (
                                        <div style={{fontSize:15, paddingLeft:8, color: 'rgba(255, 255, 255, 0.38)'}}>
                                            Resend in
                                        </div>
                                    )}
                                </div>
                            )}
                        </OtpTimer>

                    </div>
                    <div className="flex justify-center">
                    <input style={{marginTop:30, textTransform:"none"}} type="submit" value="Verify & Save Changes" onClick={handleSubmit} />
                    </div>
                </form>
            </Modal>
        </>
    );

};

export default OtpComponent;
