import React from 'react';

import style from './index.module.scss';

export interface MultiTabOptionType {
  type: string;
  imgUrl: string;
  isDisabled?: boolean;
}

export const MultiTabOption = ({
  type,
  count,
  isActive,
  onSelect,
}: {
  type: MultiTabOptionType;
  count: number;
  isActive: boolean;
  onSelect: () => void;
}) => {
  return (
    <div
      className={`${style[`multi-tab-option${isActive ? '-active' : type.isDisabled? '-disabled' :"" }`]} w-1/${count}`}
      onClick={!type.isDisabled ? onSelect : undefined}>
      <img src={type.imgUrl} alt="" />
      <span>{type.type}</span>
    </div>
  );
};

const MultiTabSelect = ({
  options,
  onChange,
  selectedOption
}: {
  options: MultiTabOptionType[];
  onChange: (type: string) => void;
  selectedOption: string;
}) => {
  return (
    <div className={`${style['multi-tab-options']}`}>
      {options?.map((option) => {
        return (
          <MultiTabOption
            key={option.type}
            type={option}
            count={options.length}
            isActive={selectedOption === option.type}
            onSelect={() => onChange(option.type)}
          />
        );
      })}
    </div>
  );
};

export default MultiTabSelect;
