import React, { useState } from 'react';
import { format, parse } from 'date-fns';

import CSVDownload from './../../assets/blukexport/csv_download_icon.svg';
import PinDownload from './../../assets/blukexport/pin_download_icon.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import { InputElement } from 'components/ui/Form/Input';
import { Select, Option } from 'components/ui/Form/Select';
import { Modal } from 'components/ui/Modal';
import MultiTabSelect, { MultiTabOptionType } from 'components/ui/Tab/MultiTabSelect';
import { BulkExportCommonProps, BulkExportData, CUSTOM_RANGE_TYPE, EMPLOYEE_STATUS, getInitialData, ONGOING_DOWNLOAD_ERROR, REIMBURSEMENT_STATUS } from './types';
import DateRangeSelector from './components/DateRangeSelector';
import FileDownloadInfo from './components/FileDownloadInfo';
import EmailExportButton from './components/EmailExportButton';
import ErrorDisplay from './components/ErrorDisplay';
import withBulkExport from '.';
import api from 'api';
import { BULK_DOWNLOAD_TIME_LIMIT, CURRENT_FINANCIAL_YEAR_PRESET_RANGE } from './components/utils';
import DownloadCSVButton from './components/DownloadCSVButton';

import styles from './index.module.scss';

const DownloadTypes = {
  CSV: 'CSV Report',
  REIMBURSEMENT: 'Reimbursement Proofs',
};

const getDownloadOptions = (isOnFreePlan: boolean) : MultiTabOptionType[] => ([
  {
    type: DownloadTypes.CSV,
    imgUrl: getStaticMediaUrl(CSVDownload),
  },
  {
    type: DownloadTypes.REIMBURSEMENT,
    imgUrl: getStaticMediaUrl(PinDownload),
    isDisabled: isOnFreePlan
  },
]);

interface ReimbursementProofProps extends BulkExportCommonProps {
  types: any[][];
  statusList: any[][];
  defaultType: string;
  defaultStatus: string;
  defaultFromDate: string;
  defaultToDate: string;
}

const ReimbursementProof = ({
  types,
  statusList,
  defaultType,
  defaultStatus,
  defaultFromDate,
  defaultToDate,
  isFormOpen,
  onClose,
  onEmailConfirm,
  hasOngoingDownload,
  isOnFreePlan
}: ReimbursementProofProps) => {
  const [downloadOption, setDownloadOption] = useState<string>(isOnFreePlan
    ? DownloadTypes.CSV 
    : DownloadTypes.REIMBURSEMENT
  );

  const [formData, setFormData] = useState<BulkExportData>(() => getInitialData(
    {
      type: defaultType,
      reimbursementStatus: defaultStatus,
      startDate: defaultFromDate ? parse(defaultFromDate, 'dd/MM/yyyy', new Date()): null,
      endDate: defaultToDate ? parse(defaultToDate, 'dd/MM/yyyy', new Date()): null,
      dateRangeType: (defaultFromDate || defaultToDate) ? CUSTOM_RANGE_TYPE : ""
    }
  ));

  const updateFormState = (propName: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [propName]: value,
    }));
  };

  const updateDateRange = (data: string, startDate: Date|null, endDate: Date|null) => {
    setFormData((prevData) => ({
      ...prevData,
      dateRangeType: data,
      startDate: startDate,
      endDate: endDate
    }));
  };

  const setDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    updateFormState(event.target.name, event.currentTarget.value);
  };

  const getPayload = () => {
    if (!formData.startDate || !formData.endDate) {
      return null;
    }

    return {
      from_date: format(formData.startDate, 'yyyy-MM-dd'),
      to_date: format(formData.endDate, 'yyyy-MM-dd'),
      people_status: formData.employeeStatus ? formData.employeeStatus : null,
      reimbursement_status: formData.reimbursementStatus ? formData.reimbursementStatus : null,
      reimbursement_type: formData.type ? formData.type : null,
    };
  };

  const initiateExport = () => {
    updateFormState('isLoading', true);
    updateFormState('error', false);
    
    const payload = getPayload();

    if (!!payload && !formData.hasValidationError) {    
      api.bulkDownloads
        .initiateOrgReimbursementProofs(payload)
        .then((response) => {
          onEmailConfirm(response.id);
        })
        .catch((error: Error) => {
          updateFormState('error', error?.message ?? true);
        })
        .finally(() => {
          updateFormState('isLoading', false);
        });
    } else {
      updateFormState('error', 'Please select valid date range');
      updateFormState('isLoading', false);
    }
  };

  return (
    <>
      <Modal showModal={isFormOpen} closeModal={onClose} className={styles['bulk-export-card']}>
        <form className={`${styles['bulk-export-card-content']}`}>
          <h3 className={`${styles['bulk-export-card-title']} text-white-o-80`}>
            Download Reimbursement Reports
          </h3>
          <div className="my-12 text-2xl font-bold text-white-o-50">
            <InputElement label="Select format" className="my-auto mb-10 relative">
              {
                isOnFreePlan && 
                <span 
                  className="tag--warning absolute right-0"
                  style={{top:"12px"}}>
                  PRO FEATURE
                </span>
              }
              <MultiTabSelect
                options={getDownloadOptions(isOnFreePlan)}
                onChange={setDownloadOption}
                selectedOption={downloadOption}
              />
            </InputElement>
            <DateRangeSelector
              id="bulk-export-popup"
              dateRangeType={formData.dateRangeType}
              startDate={formData.startDate}
              endDate={formData.endDate}
              onChangeDateRange={updateFormState}
              onChangeDateRangeType={updateDateRange}
              onError={(data) => updateFormState('hasValidationError', !!data)}
              isDisabled={!!formData.isLoading}
              presetRange={CURRENT_FINANCIAL_YEAR_PRESET_RANGE}
            />
            <div className="flex gap-8">
              <InputElement label="Reimbursement Status" className="my-auto">
                <Select
                  name="reimbursementStatus"
                  onChange={setDetails}
                  value={formData.reimbursementStatus}
                  disabled={!!formData.isLoading}>
                  {Object.values(statusList)
                    ?.filter((status: (string | number)[]) => status.length >= 2)
                    .sort((a, b) => (a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0))
                    .map((status: (string | number)[]) => (
                      <Option key={status[0]} value={status[0]}>
                        {status[1]}
                      </Option>
                    ))}
                </Select>
              </InputElement>
              {downloadOption !== DownloadTypes.CSV && (
                <InputElement label="Employee Status" className="my-auto">
                  <Select
                    name="employeeStatus"
                    onChange={setDetails}
                    value={formData.employeeStatus}
                    disabled={!!formData.isLoading}>
                    <Option value="">All Employees</Option>
                    <Option value={EMPLOYEE_STATUS.ACTIVE}>Active</Option>
                    <Option value={EMPLOYEE_STATUS.DISMISSED}>Dismissed</Option>
                  </Select>
                </InputElement>
              )}
            </div>
            <InputElement label="Reimbursement Type" className="my-auto">
              <Select
                name="type"
                onChange={setDetails}
                value={formData.type}
                disabled={!!formData.isLoading}>
                <Option value="">All</Option>
                {Object.values(types)?.map((type: (string | number)[]) =>
                  type.length >= 2 ? (
                    <Option key={type[0]} value={type[0]}>
                      {type[1]}
                    </Option>
                  ) : null,
                )}
              </Select>
            </InputElement>

            {!hasOngoingDownload && <FileDownloadInfo time={BULK_DOWNLOAD_TIME_LIMIT} />}
            <ErrorDisplay error={hasOngoingDownload ? ONGOING_DOWNLOAD_ERROR : formData.error} />
          </div>
          <div className="flex justify-start">
            {downloadOption === DownloadTypes.REIMBURSEMENT ? (
              <EmailExportButton
                onClick={initiateExport}
                isLoading={!!formData.isLoading}
                isDisabled={hasOngoingDownload}
              />
            ) : (
              <DownloadCSVButton
                link={'csv/organization/reimbursements'}
                payload={getPayload()}
              />
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default withBulkExport<ReimbursementProofProps>(ReimbursementProof, {
  confirmHeader: "We'll email you the reimbursement proofs",
});
