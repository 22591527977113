import { WarningAlert } from 'components/ui/Alert';
import React from 'react';

const ErrorDisplay = ({
  error
}: {
  error?: string | boolean;
}) => {
  if (!error) {
    return null;
  }
  return (
    <WarningAlert className="text-left">
      {typeof error === 'string'
        ? error
        : 'Unable to initiate bulk download, please contact support'}
    </WarningAlert>
  );
};

export default ErrorDisplay;
