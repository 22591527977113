import React, { useState } from 'react';
import { parse, format } from 'date-fns';

import { Modal } from 'components/ui/Modal';
import { BulkExportCommonProps, BulkExportData, getInitialData, ONGOING_DOWNLOAD_ERROR } from './types';
import FileDownloadInfo from './components/FileDownloadInfo';
import EmailExportButton from './components/EmailExportButton';
import api from 'api';
import ErrorDisplay from './components/ErrorDisplay';
import withBulkExport from '.';
import { BULK_DOWNLOAD_TIME_LIMIT, getMonthsRange } from './components/utils';
import { InputElement } from 'components/ui/Form/Input';
import { Select, Option } from 'components/ui/Form/Select';

import styles from './index.module.scss';

const getPresetDataForEmployeePayslips = (financialYearList: {[key: string]: {
  text: string,
  startDate: string,
  endDate: string
}}) => {
  const fyList = Object.keys(financialYearList)
    .reverse()
    .map((key) => ({
      text: financialYearList[key].text,
      startDate: parse(financialYearList[key].startDate, 'dd/MM/yyyy', new Date()),
      endDate: parse(financialYearList[key].endDate, 'dd/MM/yyyy', new Date())
    }));

  return [
    getMonthsRange(new Date(), 3),
    getMonthsRange(new Date(), 6),
    ...fyList,   
  ];
}

const EmployeePayslips = ({
  isFormOpen,
  onClose,
  onEmailConfirm,
  peopleOrgId,
  hasOngoingDownload,
  financialYearList,
  selectedFinancialYear
}: {
  peopleOrgId?: number;
  financialYearList: {[key: string]: {
    text: string,
    startDate: string,
    endDate: string
  }};
  selectedFinancialYear?: string;
} & BulkExportCommonProps) => {
  const [formData, setFormData] = useState<BulkExportData>(() => getInitialData(
    {
      dateRangeType: selectedFinancialYear ? financialYearList[selectedFinancialYear]['text'] : ""
    }
  ));

  const [presetRangeInfo, setPresetRangeInfo] = useState<{
    text: string;
    startDate: Date;
    endDate: Date;
  }[]>(() => getPresetDataForEmployeePayslips(financialYearList))

  const updateFormState = (propName: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [propName]: value,
    }));
  };

  const setDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    updateFormState(event.target.name, event.currentTarget.value);
  };

  const initiateExport = () => {
    updateFormState('isLoading', true);
    updateFormState('error', false);

    const selectedRangeData = presetRangeInfo.find(data => data.text === formData.dateRangeType);

    if (selectedRangeData && selectedRangeData.startDate && selectedRangeData.endDate && !formData.hasValidationError) {
      const payload = {
        from_date: format(selectedRangeData.startDate, 'yyyy-MM-dd'),
        to_date: format(selectedRangeData.endDate, 'yyyy-MM-dd'),
      };

      api.bulkDownloads
        .initiateEmployeePayslip(payload, peopleOrgId)
        .then((response) => {
          onEmailConfirm(response.id);
        })
        .catch((error: Error) => {
          updateFormState('error', error?.message ?? true);
        })
        .finally(() => {
          updateFormState('isLoading', false);
        });
    } else {
      updateFormState('error', 'Please select valid date range');
      updateFormState('isLoading', false);
    }
  };

  return (
    <>
      <Modal showModal={isFormOpen} closeModal={onClose} className={styles['bulk-export-card']}>
        <form className={`${styles['bulk-export-card-content']}`}>
          <h3 className={`${styles['bulk-export-card-title']} text-white-o-80`}>
            Download Your Payslips
          </h3>
          <div className="my-12 text-2xl font-bold text-white-o-50">
            <InputElement label="Date Range" className="my-auto">
              <Select
                name="dateRangeType"
                onChange={setDetails}
                value={formData.dateRangeType}
                disabled={!!formData.isLoading}>
                {presetRangeInfo.map((option) => (
                  <Option key={option.text} value={option.text}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            </InputElement>
            {!hasOngoingDownload && <FileDownloadInfo time={BULK_DOWNLOAD_TIME_LIMIT} />}
            <ErrorDisplay error={hasOngoingDownload ? ONGOING_DOWNLOAD_ERROR : formData.error} />
          </div>
          <div className="flex justify-start">
            <EmailExportButton
              onClick={initiateExport}
              isLoading={!!formData.isLoading}
              isDisabled={hasOngoingDownload}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default withBulkExport(EmployeePayslips, {
  confirmHeader: "We'll email you the payslips",
  buttonText: "Download Payslips"
});
