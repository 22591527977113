import React, { useState } from 'react';
 
import { Modal } from 'components/ui/Modal';
import { BulkExportCommonProps, BulkExportData, EMPLOYEE_STATUS, getInitialData, ONGOING_DOWNLOAD_ERROR } from './types';
import { Select, Option } from 'components/ui/Form/Select';
import { InputElement } from 'components/ui/Form/Input';
import FileDownloadInfo from './components/FileDownloadInfo';
import EmailExportButton from './components/EmailExportButton';
import ErrorDisplay from './components/ErrorDisplay';
import withBulkExport from '.';
import api from 'api';
import { BULK_DOWNLOAD_TIME_LIMIT } from './components/utils';

import styles from './index.module.scss';

const EmployeeDocuments  = ({
  isFormOpen,
  onClose,
  onEmailConfirm,
  hasOngoingDownload
}: BulkExportCommonProps) => {
  const [formData, setFormData] = useState<BulkExportData>(() => getInitialData());

  const updateFormState = (propName: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [propName]: value
    }));
  };

  const initiateExport = () => {
    updateFormState('isLoading', true);
    updateFormState('error', false);

    const payload = {
      people_status: formData.employeeStatus ? formData.employeeStatus : null
    };

    api.bulkDownloads.initiateOrgDocuments(payload)
      .then((response) => {
        onEmailConfirm(response.id);
      })
      .catch((error: Error) => {
        updateFormState('error', error?.message ?? true);
      })
      .finally(() => {
        updateFormState('isLoading', false);
      });
  } 

  return (
    <>
      <Modal showModal={isFormOpen} closeModal={onClose} className={styles['bulk-export-card']}>
        <form className={`${styles['bulk-export-card-content']}`}>
          <h3 className={`${styles['bulk-export-card-title']} text-white-o-80`}>
            Download Employee Documents
          </h3>
          <div className="my-12 text-2xl font-bold text-white-o-50">
            <InputElement label="Employee Status" className="my-auto">
              <Select
                name="employeeStatus"
                onChange={
                  (event: React.ChangeEvent<HTMLSelectElement>) => 
                    updateFormState(event.target.name, event.currentTarget.value)
                }
                value={formData.employeeStatus}
                disabled={!!formData.isLoading}>
                <Option value="">All Employees</Option>
                <Option value={EMPLOYEE_STATUS.ACTIVE}>Active Employees</Option>
                <Option value={EMPLOYEE_STATUS.DISMISSED}>Dismissed Employees</Option>
              </Select>
            </InputElement>
            
            {!hasOngoingDownload && <FileDownloadInfo time={BULK_DOWNLOAD_TIME_LIMIT} />}
            <ErrorDisplay error={hasOngoingDownload? ONGOING_DOWNLOAD_ERROR : formData.error}/>
          </div>
          <div className="flex justify-start">
            <EmailExportButton
              onClick={initiateExport}
              isLoading={!!formData.isLoading}
              isDisabled={hasOngoingDownload}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default withBulkExport(EmployeeDocuments, {
  disableOnFreePlan: true
});
