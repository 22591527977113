import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'components/ui/Modal';
import { getStaticMediaUrl } from 'utils/Urls';
import ConfirmImg from './../../../assets/blukexport/export_confirm.png';
import { PrimaryButtonV2 } from 'components/ui/Button';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { BULK_DOWNLOAD_TIME_LIMIT } from './utils';

import styles from './../index.module.scss';

const DownloadConfimationModal = ({
  isOpen,
  onConfirm,
  headerText,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  headerText: string;
}) => {
  const emailAddress = useSelector(loggedInUserSelectors.email);

  return (
    <Modal
      closeModal={onConfirm}
      showModal={isOpen}
      disableClose={true}
      className={`${styles['bulk-export-card-confirm']}`}>
      <div className="px-10">
        <img alt="" className="mx-auto" width="200px" src={getStaticMediaUrl(ConfirmImg)} />
        <h3 className="font-bold text-center mt-16 text-4xl">{headerText}</h3>
        <p className="mt-5 text-center">
          File download can take upto {BULK_DOWNLOAD_TIME_LIMIT}. We'll email you the files
          {emailAddress ? ` on ${emailAddress}.` : '.'}
        </p>
        <div className="flex w-full justify-center mb-10 mt-16">
          <PrimaryButtonV2 onClick={onConfirm}>Okay</PrimaryButtonV2>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadConfimationModal;
