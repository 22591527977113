import React from 'react';

import { StandardButton } from 'components/ui/Button';
import { getStaticMediaUrl, prepareUrl } from 'utils/Urls';
import Export from './../../../assets/icon--export-white.svg';

const DownloadCSVButton = ({ link, payload }: { link: string; payload: any }) => {
  return (
    <a
      href={prepareUrl(`${window.location.origin}/v2/api/${link}`, payload)}
      target="_blank">
      <StandardButton className="mr-6" type="button">
        <div className="flex justify-center align-center">
          <img className="pl-10" width="45" src={getStaticMediaUrl(Export)} alt="" />
          <span className="pl-4 pr-10 capitalize font-bold text-white">Download</span>
        </div>
      </StandardButton>
    </a>
  );
};

export default DownloadCSVButton;
