import React from 'react';
import styled from 'styled-components';

import { useMutation } from 'react-query';
import { Button, Modal, ModalBody, Text, Box, Badge, Link } from '@razorpay/blade/components';
import api from 'api';
import { AppError, getAppErrorDetails } from 'utils/AppError';
import { getStaticMediaUrl } from 'utils/Urls';
import InfoImage from '../../../assets/payrollAiAssistant/Info.png';
import InfoItem1 from '../../../assets/payrollAiAssistant/Rect1.svg';
import InfoItem2 from '../../../assets/payrollAiAssistant/Rect2.svg';
import InfoItem3 from '../../../assets/payrollAiAssistant/Rect3.svg';

import { showErrorToastNotification } from 'utils/ToastEvents';

const START_AI_TAX_OPTIMIZER = 'START_AI_TAX_OPTIMIZER';

const ImageWrapper = styled.div(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.m}px) {
      display: none;
    }
  `,
);

const HeaderText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const InfoPopup = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const startTaxOptimizer = useMutation({
    mutationKey: START_AI_TAX_OPTIMIZER,
    mutationFn: () => api.payrollAiAssistant.initiateForPayrollUsers(),
    onSuccess: (data) => {
      window.location.href = `/tax-optimizer?id=${data.conversation_key}&conversation_count=${data.conversation_count}&user_name=${data.user_name}`;
    },
    onError: (error: typeof AppError) => {
      setIsLoading(false);
      const message = getAppErrorDetails(error);
      showErrorToastNotification({
        text: message.messageForUser,
        timeout: 5000,
      });
    },
  });

  const handleStartTaxOptimization = () => {
    startTaxOptimizer.mutate();
    setIsLoading(true);
  };

  return (
    (<Modal isOpen={isOpen} onDismiss={onClose} size="medium">
      <ModalBody padding="spacing.0">
        <Box display="flex">
          <ImageWrapper>
            <img
              src={getStaticMediaUrl(InfoImage)}
              alt=""
              style={{
                height: '100%',
                maxWidth: 'unset',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
              }}
            />
          </ImageWrapper>
          <Box
            display="flex"
            justifyContent="space-between"
            padding="spacing.8"
            flexDirection="column"
            height={{
              base: '500px',
              m: 'auto',
            }}
            minHeight="414px">
            <Box>
              <Badge size="small" emphasis="intense" color="positive">
                NEW
              </Badge>
              <HeaderText className="mb-1">
                Curious about unlocking additional tax savings?
              </HeaderText>
              <Text color="surface.text.gray.muted" size="large">
                Look no further. Ray has you covered!
              </Text>
            </Box>
            <InfoPopupItem
              image={getStaticMediaUrl(InfoItem1)}
              header="Smart regime break-up with suggestions"
              subHeader="No flakiness. Backed by data."
            />
            <InfoPopupItem
              image={getStaticMediaUrl(InfoItem2)}
              header="Simplified tax savings"
              subHeader="Take the guesswork out of taxes."
            />
            <InfoPopupItem
              image={getStaticMediaUrl(InfoItem3)}
              header="DIY. Period."
              subHeader="No need for last minute tips from friends"
            />
            <Box>
              <Button
                onClick={handleStartTaxOptimization}
                isLoading={isLoading}
                margin="spacing.0"
                marginRight="spacing.7">
                Try Now
              </Button>
              <Link onClick={onClose}>I’ll try later</Link>
            </Box>
          </Box>
        </Box>
      </ModalBody>
    </Modal>)
  );
};

const InfoPopupItem = ({
  image,
  header,
  subHeader,
}: {
  image: string;
  header: string;
  subHeader: string;
}) => {
  return (
    <Box display="flex" gap="spacing.5">
      <img src={image} alt="" height="36px" width="36px" />
      <Box display="flex" height="36px" flexDirection="column">
        <p style={{ fontSize: '14px', fontWeight: '700', margin: 0 }}>{header}</p>
        <Text size="small" color="surface.text.gray.muted">
          {subHeader}
        </Text>
      </Box>
    </Box>
  );
};

export default InfoPopup;
