import React, { useState } from 'react';
import { format, parse } from 'date-fns';

import { getStaticMediaUrl } from 'utils/Urls';
import { Modal } from 'components/ui/Modal';
import { BulkExportCommonProps, BulkExportData, EMPLOYEE_STATUS, getInitialData, ONGOING_DOWNLOAD_ERROR } from './types';
import MultiTabSelect, { MultiTabOptionType } from 'components/ui/Tab/MultiTabSelect';
import CSVDownload from './../../assets/blukexport/csv_download_icon.svg';
import PinDownload from './../../assets/blukexport/pin_download_icon.svg';
import { Select, Option } from 'components/ui/Form/Select';
import { InputElement } from 'components/ui/Form/Input';
import FileDownloadInfo from './components/FileDownloadInfo';
import EmailExportButton from './components/EmailExportButton';
import ErrorDisplay from './components/ErrorDisplay';
import withBulkExport from '.';
import api from 'api';
import { BULK_DOWNLOAD_TIME_LIMIT, DEFAULT_FINANCIAL_YEAR, getMonthsRange } from './components/utils';
import DownloadCSVButton from './components/DownloadCSVButton';

import styles from './index.module.scss';

const DownloadTypes = {
  CSV: 'CSV Report',
  PAYSLIP: 'Payslips',
};

const downloadOptions: MultiTabOptionType[] = [
  {
    type: DownloadTypes.CSV,
    imgUrl: getStaticMediaUrl(CSVDownload),
  },
  {
    type: DownloadTypes.PAYSLIP,
    imgUrl: getStaticMediaUrl(PinDownload),
  },
];

const getPresetDataForEmployeePayslipsAdmin = (payrollMonthList: {[key: string]: {
  text: string,
  startDate: string,
  endDate: string
}}) => {
  const monthList = Object.keys(payrollMonthList)
    .sort((key1, key2) => {
      // key format - Y-m
      const month1Str = key1.split("-");
      const month2Str = key2.split("-");
      if(month1Str[0] === month2Str[0]) {
      /*
        * The financial month keys are passed as 01 for jan,..., 12 for dec
        * If the current financial year is 2022-23, this is to sort the financial month 
        * in a way that jan, feb, march of year 2023 comes at the top of the list in the order of
        * Mar, feb, jan`23, dec`22, nov ... apr. This is done by converting string to number, and adding 12,
        * so that it becomes jan - 13, feb - 14, mar - 15 
        */
        
        const m1 = +month1Str <= 3 ? +month1Str + 12 : +month1Str;
        const m2 = +month2Str <= 3 ? +month2Str + 12 : +month2Str;
        return  m2 - m1;
      } 
      return +month2Str[0] - +month1Str[0];
      
    })
    .map((key) => ({
      text: payrollMonthList[key].text,
      startDate: parse(payrollMonthList[key].startDate, 'dd/MM/yyyy', new Date()),
      endDate: parse(payrollMonthList[key].endDate, 'dd/MM/yyyy', new Date())
    }));

  return [
    getMonthsRange(new Date(), 3),
    getMonthsRange(new Date(), 6),
    DEFAULT_FINANCIAL_YEAR,
    ...monthList,
  ];
}


const EmployeePayslipsAdmin = ({
  isFormOpen,
  onClose,
  onEmailConfirm,
  hasOngoingDownload,
  payrollMonthList,
  selectedPayrollMonth
}: {
  payrollMonthList: {[key: string]: {
    text: string,
    startDate: string,
    endDate: string
  }};
  selectedPayrollMonth?: string;
} & BulkExportCommonProps) => {
  const [formData, setFormData] = useState<BulkExportData>(() => getInitialData({
    dateRangeType: selectedPayrollMonth ? payrollMonthList[selectedPayrollMonth]?.text : undefined
  }));
  const [downloadOption, setDownloadOption] = useState<string>(DownloadTypes.PAYSLIP);
  const [presetRangeInfo, setPresetRangeInfo] = useState<{
    text: string;
    startDate: Date;
    endDate: Date;
  }[]>(() => getPresetDataForEmployeePayslipsAdmin(payrollMonthList))

  const updateFormState = (propName: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [propName]: value,
    }));
  };

  const setDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    updateFormState(event.target.name, event.currentTarget.value);
  };

  const initiateExport = () => {
    updateFormState('isLoading', true);
    updateFormState('error', false);

    const selectedRangeData = presetRangeInfo.find(data => data.text === formData.dateRangeType);

    if (selectedRangeData && selectedRangeData.startDate && selectedRangeData.endDate && !formData.hasValidationError) {
      const payload = {
        from_date: format(selectedRangeData.startDate, 'yyyy-MM-dd'),
        to_date: format(selectedRangeData.endDate, 'yyyy-MM-dd'),
        people_status: formData.employeeStatus ? formData.employeeStatus : null
      };

      api.bulkDownloads
        .initiateOrgPayslip(payload)
        .then((response) => {
          onEmailConfirm(response.id);
        })
        .catch((error: Error) => {
          updateFormState('error', error?.message ?? true);
        })
        .finally(() => {
          updateFormState('isLoading', false);
        });
    } else {
      updateFormState('error', 'Please select valid date range');
      updateFormState('isLoading', false);
    }
  };
  
  return (
    <>
      <Modal showModal={isFormOpen} closeModal={onClose} className={styles['bulk-export-card']}>
        <form className={`${styles['bulk-export-card-content']}`}>
          <h3 className={`${styles['bulk-export-card-title']} text-white-o-80`}>
            Download Employee Payslips
          </h3>
          <div className="my-12 text-2xl font-bold text-white-o-50">
            {/* 
            Disabled this for now, will be enabled later
            <InputElement label="Select format" className="my-auto mb-10">
              <MultiTabSelect
                options={downloadOptions}
                onChange={setDownloadOption}
                selectedOption={downloadOption}
              />
            </InputElement> */}
            <InputElement label="Date Range" className="my-auto">
              <Select
                name="dateRangeType"
                onChange={setDetails}
                value={formData.dateRangeType}
                disabled={!!formData.isLoading}>
                {presetRangeInfo.map((option) => (
                  <Option key={option.text} value={option.text}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            </InputElement>
            <InputElement label="Employee Status" className="my-auto">
              <Select
                name="employeeStatus"
                onChange={setDetails}
                value={formData.employeeStatus}
                disabled={!!formData.isLoading}>
                <Option value="">All Employees</Option>
                <Option value={EMPLOYEE_STATUS.ACTIVE}>Active Employees</Option>
                <Option value={EMPLOYEE_STATUS.DISMISSED}>Dismissed Employees</Option>
              </Select>
            </InputElement>
            {!hasOngoingDownload && <FileDownloadInfo time={BULK_DOWNLOAD_TIME_LIMIT} />}
            <ErrorDisplay error={hasOngoingDownload ? ONGOING_DOWNLOAD_ERROR : formData.error} />
          </div>
          <div className="flex justify-start">
            {downloadOption === DownloadTypes.PAYSLIP ? (
              <EmailExportButton
                onClick={initiateExport}
                isLoading={!!formData.isLoading}
                isDisabled={hasOngoingDownload}
              />
            ) : (
              <DownloadCSVButton
                link={'csv/organization/payslip'}
                payload={{
                  from_date: formData.startDate ? format(formData.startDate, 'yyyy-MM-dd') : null,
                  to_date: formData.endDate ? format(formData.endDate, 'yyyy-MM-dd') : null,
                  people_status: formData.employeeStatus ? formData.employeeStatus : null,
                }}
              />
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default withBulkExport(EmployeePayslipsAdmin, {
  confirmHeader: "We'll email you the payslips",
  buttonText: "Download Payslips",
  disableOnFreePlan: true
});
