import React from 'react';
import { useSelector } from 'react-redux';

import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

import styles from './../index.module.scss';

const FileDownloadInfo = ({ time }: { time: string }) => {
  const email = useSelector(loggedInUserSelectors.email);

  return (
    <p className={`${styles['bulk-export-card-info']}`}>
      File download can take upto {time}. We will email the files to
      {email ? ` you at ${email}.` : ' your email address.'}
    </p>
  );
};

export default FileDownloadInfo;
