import { format, endOfMonth } from 'date-fns';

import { getFinancialYearFromDate, getMonthsForFinancialYear } from "utils/Dates";

export const getDefaultFinancialYear = () => {
    const currentDate = new Date();
    let month = currentDate.getMonth();
    const financialYear = getFinancialYearFromDate(currentDate);

    if(month < 3) {
        month = month + 12;
    }

    return {
        text: `Current Financial Year (${format(new Date(financialYear,3,1), 'MMMM’yy')}
            - ${format(currentDate, 'MMMM’yy')})`,
        startDate: new Date(financialYear, 3, 1), 
        endDate: new Date(financialYear, month + 1, 0)
    };
}

export const getMonthsRange = (currentDate: Date, numberOfMonths: number, text?: string) => {
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    return {
        text: `Last ${text ?? numberOfMonths} Months`,
        startDate: new Date(year, month - numberOfMonths, 1), 
        endDate: endOfMonth(currentDate)
    };
}

export const getCurrentFinancialYearPresetRange = () => {
    const FINANCIAL_YEAR_MONTHS = getMonthsForFinancialYear(getFinancialYearFromDate(new Date()));
    const DEFAULT_FINANCIAL_YEAR = getDefaultFinancialYear();
    return [DEFAULT_FINANCIAL_YEAR, ...FINANCIAL_YEAR_MONTHS];
}

export const CURRENT_FINANCIAL_YEAR_PRESET_RANGE = getCurrentFinancialYearPresetRange();
export const DEFAULT_FINANCIAL_YEAR = getDefaultFinancialYear();

export const BULK_DOWNLOAD_TIME_LIMIT = '20 minutes';