export interface BulkExportData {
  dateRangeType?: string;
  startDate?: Date|null;
  endDate?: Date|null;
  type?: string;
  reimbursementStatus?: string;
  employeeStatus?: string;
  error?: boolean | string;
  isLoading?: boolean;
  hasValidationError? : boolean;
}

export const getInitialData = (initialData?: BulkExportData): BulkExportData => ({
  dateRangeType: initialData?.dateRangeType ?? '',
  startDate: initialData?.startDate ?? null,
  endDate: initialData?.endDate ?? null,
  type: initialData?.type ?? '',
  reimbursementStatus: initialData?.reimbursementStatus ?? '',
  employeeStatus: initialData?.employeeStatus ?? '',
  error: false,
  hasValidationError: false,
});

export const CUSTOM_RANGE_TYPE = 'Custom Range';

export interface BulkExportCommonProps {
    isFormOpen: boolean;
    onClose: () => void;
    onEmailConfirm: (id: number) => void;
    hasOngoingDownload: boolean;
    isOnFreePlan: boolean;
}

export const EMPLOYEE_STATUS = {
    ACTIVE: "ACTIVE",
    DISMISSED: "DISMISSED"
}

export const REIMBURSEMENT_STATUS = {
    APPROVED: "Approved",
    REJECTED: "Rejected"
}

export const ONGOING_DOWNLOAD_ERROR = 'Some files are being downloaded right now. Please wait for the previous file download to complete, before requesting for a new download.';