import React, { useEffect, useState } from 'react';

import { Modal } from 'components/ui/Modal';
import { BulkExportCommonProps, BulkExportData, EMPLOYEE_STATUS, getInitialData, ONGOING_DOWNLOAD_ERROR } from './types';
import { Select, Option } from 'components/ui/Form/Select';
import { InputElement } from 'components/ui/Form/Input';
import FileDownloadInfo from './components/FileDownloadInfo';
import EmailExportButton from './components/EmailExportButton';
import ErrorDisplay from './components/ErrorDisplay';
import withBulkExport from '.';
import api from 'api';
import { getFinancialYearFromDate } from 'utils/Dates';
import { BULK_DOWNLOAD_TIME_LIMIT } from './components/utils';
import MultiTabSelect, { MultiTabOptionType } from 'components/ui/Tab/MultiTabSelect';
import CSVDownload from './../../assets/blukexport/csv_download_icon.svg';
import PinDownload from './../../assets/blukexport/pin_download_icon.svg';
import { getStaticMediaUrl } from 'utils/Urls';
import DownloadCSVButton from './components/DownloadCSVButton';

import styles from './index.module.scss';

const DownloadTypes = {
  CSV: 'CSV Report',
  ITR: 'Tax Deduction Proofs',
};

const getDownloadOptions = (isOnFreePlan: boolean): MultiTabOptionType[] => ([
  {
    type: DownloadTypes.CSV,
    imgUrl: getStaticMediaUrl(CSVDownload),
  },
  {
    type: DownloadTypes.ITR,
    imgUrl: getStaticMediaUrl(PinDownload),
    isDisabled: isOnFreePlan
  },
]);

const TaxDeductionReports = ({
  isFormOpen,
  onClose,
  onEmailConfirm,
  hasOngoingDownload,
  financialYearList,
  selectedFinancialYear,
  isOnFreePlan
}: BulkExportCommonProps & {
  financialYearList: {[key: string]: {
    text: string,
    startDate: string,
    endDate: string
  }};
  selectedFinancialYear?: string;
}) => {
  const [formData, setFormData] = useState<BulkExportData>(() => getInitialData());
  const [downloadOption, setDownloadOption] = useState<string>(isOnFreePlan 
    ? DownloadTypes.CSV 
    : DownloadTypes.ITR
  );

  const updateFormState = (propName: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [propName]: value,
    }));
  };

  useEffect(() => {
    updateFormState('dateRangeType', selectedFinancialYear?.toString());
  }, [selectedFinancialYear]);

  const getPayload = () => {
    return {
      financial_year: formData.dateRangeType ?? getFinancialYearFromDate(new Date()).toString(),
      people_status: formData.employeeStatus ? formData.employeeStatus : null,
    }
  };

  const initiateExport = () => {
    updateFormState('isLoading', true);
    updateFormState('error', false);

    api.bulkDownloads
      .initiateOrgTaxDeductionProofs(getPayload())
      .then((response) => {
        onEmailConfirm(response.id);
      })
      .catch((error: Error) => {
        updateFormState('error', error?.message ?? true);
      })
      .finally(() => {
        updateFormState('isLoading', false);
      });
  };

  const fyList = Object.keys(financialYearList)
    .reverse()
    .map((key) => ({
      key: key,
      value: financialYearList[key]['text'],
    }));

  return (
    <>
      <Modal showModal={isFormOpen} closeModal={onClose} className={styles['bulk-export-card']}>
        <form className={`${styles['bulk-export-card-content']}`}>
          <h3 className={`${styles['bulk-export-card-title']} text-white-o-80`}>
            Download Tax Deduction Reports
          </h3>
          <div className="my-12 text-2xl font-bold text-white-o-50">
            <InputElement label="Select format" className="my-auto mb-10 relative">
              {
                isOnFreePlan && 
                <span 
                  className="tag--warning absolute right-0"
                  style={{top:"12px"}}>
                  PRO FEATURE
                </span>
              }
              <MultiTabSelect
                options={getDownloadOptions(isOnFreePlan)}
                onChange={setDownloadOption}
                selectedOption={downloadOption}
              />
            </InputElement>
            <InputElement label="Financial Year" className="my-auto">
              <Select
                name="dateRangeType"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  updateFormState(event.target.name, event.currentTarget.value)
                }
                value={formData.dateRangeType}
                disabled={!!formData.isLoading}>
                {fyList?.map((fy) => (
                  <Option value={fy.key}>{fy.value}</Option>
                ))}
              </Select>
            </InputElement>
            <InputElement label="Employee Status" className="my-auto">
              <Select
                name="employeeStatus"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  updateFormState(event.target.name, event.currentTarget.value)
                }
                value={formData.employeeStatus}
                disabled={!!formData.isLoading}>
                <Option value="">All Employees</Option>
                <Option value={EMPLOYEE_STATUS.ACTIVE}>Active Employees</Option>
                <Option value={EMPLOYEE_STATUS.DISMISSED}>Dismissed Employees</Option>
              </Select>
            </InputElement>

            {!hasOngoingDownload && <FileDownloadInfo time={BULK_DOWNLOAD_TIME_LIMIT} />}
            <ErrorDisplay error={hasOngoingDownload? ONGOING_DOWNLOAD_ERROR : formData.error}/>
          </div>
          <div className="flex justify-start">
            {downloadOption === DownloadTypes.ITR ? (

            <EmailExportButton
              onClick={initiateExport}
              isLoading={!!formData.isLoading}
              isDisabled={hasOngoingDownload}
            />
            ) : (
              <DownloadCSVButton
                link={'csv/organization/tax-deductions'}
                payload={getPayload()}
              />
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default withBulkExport(TaxDeductionReports);
