import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import MultiSelect from 'components/ui/Dropdown/MultiSelect';
import api from 'api';
import { AppError } from 'utils/AppError';

const customCoulmnsOptions = [
  {
    key: 'dob',
    value: 'Date Of Birth',
  },
  {
    key: 'hireDate',
    value: 'Hire Date',
  },
  {
    key: 'gender',
    value: 'Gender',
  },
  {
    key: 'department',
    value: 'Department',
  },
  {
    key: 'designation',
    value: 'Designation',
  },
  {
    key: 'location',
    value: 'Location',
  },
  {
    key: 'locationPT',
    value: 'PT Location',
  },
  {
    key: 'email',
    value: 'Email',
  },
  {
    key: 'leftOrg',
    value: 'Has Left The Org',
  },
  {
    key: 'deactivateDate',
    value: 'Relieving Date',
  },
  {
    key: 'pan',
    value: 'Pan',
  },
  {
    key: 'pfUAN',
    value: 'PF UAN',
  },
  {
    key: 'esiNumber',
    value: 'ESI Number',
  },
  {
    key: 'bankAccNo',
    value: 'Bank Acc. No',
  },
  {
    key: 'ifsc',
    value: 'IFSC Code',
  },
];

const SalaryRegisterFilters = ({
  orgColumns,
  optionalUserData
} : {
  orgColumns: string[];
  optionalUserData: {
    key: string;
    value: string;
  }[]
}) => {
  const [appliedColumns, setAppliedColumns] = useState<string[]>(orgColumns ?? []);

  const sendMessage = (keys: string[]) => {
    let msg = { eventName: 'update-sr-filter', dataKeys: keys };
    window.postMessage(msg, window.location.origin);
  }

  const onChange = (keys: string[]) => {
    setAppliedColumns(keys);
    sendMessage(keys);
    if(JSON.stringify(keys.sort()) !== JSON.stringify(orgColumns.sort())) {
      syncBackend.mutate(keys);
    }
  };

  useEffect(() => {
    sendMessage(appliedColumns);
  }, []);

  const syncBackend = useMutation(
    (columns: string[]) =>
      api.companyOnboarding.updateSalaryRegisterPreference(columns),
    {
      onSuccess: (data) => {
      },
      onError: (error: typeof AppError) => {},
    },
  );

  return (
    <>
      <MultiSelect
        id="sr-filter"
        options={[...customCoulmnsOptions, ...optionalUserData] ?? []}
        name={'Employee Data'}
        value={appliedColumns}
        onChange={onChange}
        disableChipsDisplay={false}
        disableAllOption={false}
      />
    </>
  );
};

export default SalaryRegisterFilters;
