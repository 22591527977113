import React, { useEffect, useState } from 'react';

import DatePicker from 'components/ui/DatePicker';
import { InputElement } from 'components/ui/Form/Input';
import { Select, Option } from 'components/ui/Form/Select';
import { CUSTOM_RANGE_TYPE } from '../types';
import { WarningAlert } from 'components/ui/Alert';

import style from './../index.module.scss';

const DateRangeSelector = ({
  id,
  dateRangeType,
  onChangeDateRangeType,
  startDate,
  endDate,
  onChangeDateRange,
  onError,
  isDisabled,
  presetRange,
  label,
}: {
  id: string;
  dateRangeType?: string;
  onChangeDateRangeType: (data: string, startDate: Date | null, endDate: Date | null) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  onChangeDateRange: (propName: string, data?: Date | null) => void;
  onError?: (error: string) => void;
  isDisabled?: boolean;
  presetRange?: {
    text: string;
    startDate: Date;
    endDate: Date;
  }[];
  label?: string;
}) => {
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setError('Invalid date range');
    } else {
      setError('');
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (presetRange && presetRange.length && dateRangeType !== CUSTOM_RANGE_TYPE) {
      let selectedPresetIdx = 0; 
      if(dateRangeType) {
        selectedPresetIdx = presetRange.map(e => e.text).indexOf(dateRangeType); 
        if(selectedPresetIdx < 0) {
            selectedPresetIdx = 0;
        }
      }  
      onChangeDateRangeType(
        presetRange[selectedPresetIdx].text,
        presetRange[selectedPresetIdx].startDate,
        presetRange[selectedPresetIdx].endDate,
      );
    }
  }, []);

  useEffect(() => {
    onError && onError(error);
  }, [error]);

  const onDateRangeChange = (selectedDate: Date | null, changeValue: string) => {
    onChangeDateRange(changeValue, selectedDate);
  };

  const onDateRangeTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    let startDate = null,
      endDate = null;
    if (selectedOption !== CUSTOM_RANGE_TYPE) {
      const dataset = event.target.options[event.target.selectedIndex].dataset;
      startDate = new Date(dataset.startDate ?? '');
      endDate = new Date(dataset.endDate ?? '');
    }
    onChangeDateRangeType(selectedOption, startDate, endDate);
  };

  return (
    <>
      {presetRange && presetRange.length && (
        <InputElement label={label ?? 'Date Range'} className="my-auto">
          <Select
            name="dateRangeType"
            onChange={onDateRangeTypeChange}
            value={dateRangeType || ''}
            disabled={isDisabled}
            className={dateRangeType === CUSTOM_RANGE_TYPE ? 'mb-5' : 'mb-0'}>
            {presetRange.map((element) => (
              <Option
                value={element.text}
                key={element.text}
                data-start-date={element.startDate}
                data-end-date={element.endDate}>
                {element.text}
              </Option>
            ))}
            <Option value={CUSTOM_RANGE_TYPE}>Select Custom Range</Option>
          </Select>
        </InputElement>
      )}
      <div className="mb-10">
        {(dateRangeType === CUSTOM_RANGE_TYPE || !presetRange) && (
          <>
            <div className="flex gap-3">
              <InputElement label="From Date" className="my-auto">
                <DatePicker
                  className={style['date-picker']}
                  id={`${id}-datepicker-start-date`}
                  onChange={(value) => onDateRangeChange(value, 'startDate')}
                  value={startDate ?? null}
                  pickerType="past"
                  isDisabled={isDisabled}
                />
              </InputElement>
              <span className="pt-16">-</span>
              <InputElement label="To Date" className="my-auto">
                <DatePicker
                  id={`${id}-datepicker-end-date`}
                  className={style['date-picker']}
                  onChange={(value) => onDateRangeChange(value, 'endDate')}
                  value={endDate ?? null}
                  pickerType="past"
                  isDisabled={isDisabled}
                />
              </InputElement>
            </div>
            {error && <WarningAlert className="mt-1">{error}</WarningAlert>}
          </>
        )}
      </div>
    </>
  );
};

export default DateRangeSelector;
