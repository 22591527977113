import React from 'react';

import { StandardButton } from 'components/ui/Button';
import { getStaticMediaUrl } from 'utils/Urls';
import EmailIcon from './../../../assets/icon--email.svg';
import Spinner from 'components/ui/Spinner';

const EmailExportButton = ({
  onClick,
  isDisabled,
  isLoading,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // This is to cancel the form sumbmition
    onClick();
  }
  return (
    <StandardButton className="mr-6" onClick={onButtonClick} disabled={isDisabled || isLoading}>
      <div className="flex justify-center align-center">
        <img className="pl-10" src={getStaticMediaUrl(EmailIcon)} alt="" />
        <span className="pl-4 pr-10 capitalize font-bold text-white">Email</span>
        {isLoading && (
          <span className="ml-2 relative top-2">
            <Spinner size={14} />
          </span>
        )}
      </div>
    </StandardButton>
  );
};

export default EmailExportButton;
