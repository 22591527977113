export const WidgetTypes = {
  NOTIFICATION_COMPONENT: 'NOTIFICATION_COMPONENT',
  REIMBURSEMENT_BULK_EXPORT: 'REIMBURSEMENT_BULK_EXPORT',
  EMPLOYEE_PAYSLIPS_EXPORT: 'EMPLOYEE_PAYSLIPS_EXPORT',
  EMPLOYEE_PAYSLIPS_ADMIN_EXPORT: 'EMPLOYEE_PAYSLIPS_ADMIN_EXPORT',
  EMPLOYEE_DOCUMENTS_EXPORT: 'EMPLOYEE_DOCUMENTS_EXPORT',
  TAX_DEDUCTION_EXPORT: 'TAX_DEDUCTION_EXPORT',
  BULK_DOWLOAD_POLL: 'BULK_DOWNLOAD_POLL',
  TOAST_SYSTEM: 'TOAST_SYSTEM',
  OTP_COMPONENT: 'OTP_COMPONENT',
  SALARY_REGISTER_FILTERS: 'SALARY_REGISTER_FILTERS',
  GLOBAL_SEARCH: 'GLOBAL_SEARCH',
  AI_TAX_OPTIMIZER: 'AI_TAX_OPTIMIZER',
  TWO_FACTOR_AUTH_MODAL: 'TWO_FACTOR_AUTH_MODAL',
};
